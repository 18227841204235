import Constants from '../../constants';
//Libraries
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';
//MUI
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MUIDataTable from "mui-datatables";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
//Compontents
import CustomSelectToolbar from '../CustomSelectToolbar';
import KeyItemEditDialog from './KeyItemEditDialog';
import EntityInfoDialog from '../MetaData/EntityInfoDialog';
import AddCustomToolbar from "../AddCustomToolbar";
import KeyItemsPrint from './KeyItemsPrint';
import { usePlantDataFromVisitID, usePlantNameFromVisitID, useVisitData } from '../../misc/UseFuncs';
import { GridImageIcon } from './KeyItemCommon';
//Actions
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import { GetKeyItemByEntityID, DeleteKeyItem, UpdateKeyItem, AddKeyItem } from '../../actions/KeyItemActions';
//Selectors
import { selectKeyItemsByVisitID, selectMultiKeyItems } from '../../selectors/KeyItemSelectors';

const constants = Constants;


export const KeyItemsVisitGrid = props => {
    const keyItems = useSelector(s => selectKeyItemsByVisitID(s, props.visitID));
    keyItems.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    const addKeyItem ={ visitID: props.visitID, entityID: props.visitID, url: 'VisitOverview/' + props.visitID, entityType: 'Visit', reference: '', notes: '', isPriority: '-' };
    return <KeyItemsGrid keyItems={ keyItems } addKeyItem={addKeyItem} visitID={props.visitID} />;
}
export const MulitKeyItemGrid = props => {
    //multi={{ entityType: props.entityType, entity: props.entity,  reference:props.reference, url:props.url }}
    const keyItems = useSelector(s => selectMultiKeyItems(s, props.visitID,props.multi.entity.id));
    const addKeyItem ={ visitID: props.visitID, entityID: props.multi.entity.id, url: 'VisitOverview/' + props.visitID, entityType: props.multi.entityType, 
        reference: props.multi.reference, notes: '', isPriority: '-' };
    return <KeyItemsGrid keyItems={ keyItems } addKeyItem={addKeyItem} visitID={props.visitID} />;
}
export const KeyItemsGrid = props => {  
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [kiSort, setKISort] = useState(null);   
    const [selectedRows, setSelectedRows] = useState([]);
    const [modalOpen, setModal] = useState(false);
    const [infoOpen, setInfo] = useState(false);
    const [printOpen, setPrint] = useState(false);
    const [filter, setFilter] = useState({entityTypes:[],referecnes:[],rows:15,page:0});
    const [keyItem,setKeyItem]=useState(null);
    const [page,setPage]=useState(0);
    const [rows,setRows]=useState(15);
    const plantData= usePlantDataFromVisitID(props.visitID);
    
    const keyItems=props.keyItems?props.keyItems.sort((a,b)=>{
        if (a.isPriority === 'Yes' && b.isPriority !== 'Yes') {
            return -1; 
        }
        if (b.isPriority === 'Yes' && a.isPriority !== 'Yes') {
            return 1;
        }
        if(a.entityType === b.entityType) { return 0; }
        if(a.entityType < b.entityType) { return -1; }
        if(b.entityType > b.entityType) {return 1;}
        // if(a.type == b.type) { return 0; }
        // if(a.type=='Safety') { return -1; }
        // if(b.type=='Safety') {return 1;}
        return 0;
    }):[];
    const visit=useVisitData(props.visitID);
    const ClickPrint = () => {
        setPrint(true);
    }
    const ClickOpen = () => {
        if (keyItem) {
            navigate(keyItem.url); 
        } else {
            alert("Select Key Item From List");
        }
    };
    const ClickEdit = () => {
        setModal(true);
    };
    const ClickInfo = () => {
        setInfo(true);
    };
    const ClickDelete = () => {
        dispatch(ConfirmAction("Do you want to delete this Key Item?", DeleteKeyItem(keyItem), null));
        setSelectedRows([]);
    };
    const editSubmit = (formVal) => {
        dispatch(UpdateKeyItem(formVal, keyItem));
        setModal(false);
    };
    const saveSubmit = (formVal) => {
        dispatch(UpdateKeyItem(formVal, keyItem));
    }
    const editClose = () => {
        setModal(false);
    }
    const onChangePriority = (value) => {
        let data = keyItem
        data.isPriority = value ? 'Yes' : '-'
        setKeyItem(data);
    }
    const openKeyItemAddDialog = () => {
        const newKeyItem=props.addKeyItem;
        newKeyItem.id = uuidv4();
        dispatch(AddKeyItem(newKeyItem));
        console.log("Set Key Item Add",newKeyItem);
        setKeyItem(newKeyItem);
        setModal(true);
    };
    const customSort = ( colName, direction) => {
        setKISort({ column: colName, direction: direction });
    }
    const columns = [
        { name: "id", label: "ID", options: { display: false,filter:false } },
        { name: "entityType", label: "Type" },  
        { name: "reference", label: "Section", options: { filterType: 'multiselect' } },
        { name: "type", label: "Category", options: { filterType: 'multiselect' } },
        { name: "notes", label: "Notes", options: { filter:false } },
        { name: "isPriority", label: "Priority", options: { filter:true } },
        { name: "imageID", label:"Image",options:{
            customBodyRender: (value, tableMeta, updateValue) => <GridImageIcon value={value}  keyItem={keyItems[tableMeta.rowIndex]} onChange={saveSubmit}   />,
            filter: false
        }},
       ];
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
        if(rowsSelected.length>0){
            const rowKI = keyItems[rowsSelected[0]];
            console.log("Set Key Item Row Sel",rowKI);
            setKeyItem(rowKI);
        } else {
            console.log("Set Key Item Row Sel Null");
            setKeyItem(null);
        }
    };
    const onFilterChange = (col, filterList, type, ind, displayData) => {
        setFilter({ ...filter, entityTypes: filterList[1], referecnes: filterList[2] });
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: true,
        fixedHeader: true,
        filter: true, viewColumns:false,
        responsive: 'standard',
        page:page,
        rowsPerPageOptions:[15,30,100,250,500],
        rowsPerPage:rows,
        onChangeRowsPerPage:(newRows)=>{
            let newPage=page;
            if(newRows>rows){
                setPage(0);
                newPage=0;
            }
            setRows(newRows);
            setFilter({ ...filter, rows:newRows,page:newPage});
            console.log("KeyItem New Rows:"+newRows);
        },
        onChangePage:(newPage)=>{
            setPage(newPage);
            setFilter({ ...filter, page:newPage});
            console.log("KeyItem New Page:"+newPage);
        },
        onColumnSortChange: customSort, onFilterChange: onFilterChange,
        customToolbar: () => {
            return (
                <AddCustomToolbar onClick={openKeyItemAddDialog} clickPrint={ClickPrint}  />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows}
                displayData={displayData} setSelectedRows={setSelectedRows}
                clickEdit={ClickEdit} clickDelete={ClickDelete} clickLink={ClickOpen} clickInfo={ClickInfo} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows,
        setRowProps: () => ({
            onDoubleClick: (row) => {
                if (selectedRows.length == 0) {
                    const targetID = row.currentTarget.id;
                    const dataRow = parseInt(targetID.substring(targetID.lastIndexOf('-') + 1));
                    if (!isNaN(dataRow)) {
                        setSelectedRows([dataRow]);
                        setKeyItem(keyItems[dataRow]);
                        ClickEdit();
                    }
                } else {
                    ClickEdit();
                }
                //;
            }
        })
    };
    return (
        <Card>
            <CardContent>
                <MUIDataTable
                    title={"Key Items"}
                    data={keyItems}
                    columns={columns}
                    options={options}
                />
                <KeyItemEditDialog modalOpen={modalOpen} modalClose={editClose}
                    keyItem={keyItem} onChangePriority={onChangePriority} onSubmit={editSubmit}  onSave={saveSubmit}
                />
                <EntityInfoDialog modalOpen={infoOpen} modalClose={() => { setInfo(false); }}
                    entityData={keyItem ? keyItem.entityData : null} />
                <Dialog open={printOpen} onClose={() => setPrint(false)} fullScreen >
                    <KeyItemsPrint keyItems={keyItems} sort={kiSort} filter={ filter } 
                        onClose={() => setPrint(false)} plant={plantData} visit={visit}/>
                </Dialog>
                
            </CardContent>
        </Card>
    );
};
