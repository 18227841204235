//Modules
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import produce from "immer";
import {useNavigate} from 'react-router-dom';
//MUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
//Compontents
import { FormulaChangeGrid} from '../FormulaHistory/FormulaChangeGrid'
import { FormulaTypeSel } from './FormulaTypeSel';
import PrintDialog from '../Print/PrintDialog';
import FormulaEdit from './FormulaEditView';
import FormulaTargetsDialog from './FormulaTargetsDialog';
import FormulaPrintView from './FormulaPrintView';
import FormulaSignatureDialog from './FormulaSignatureDialog';
//Actions
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import { FormulaCalcSummary, UpdateFormula, DeleteFormula } from '../../actions/FormulaActions';
import { ReOrderFormulaSteps } from '../../actions/FormulaStepActions';
import { DialogContent, DialogTitle } from '@mui/material';


export const FormulaSummary = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formula = useSelector(state => state.Formulas.find(f => f.id == props.formulaID));
    const [historyOpen, setHistoryOpen] = useState(false);
    const [printOpen, setPrint] = useState(false);
    const [sigOpen, setSigOpen] = useState(false);
    const [targetsOpen,setTargetsOpen]=useState(false);
    const [isMetric, setIsMetric] = useState(false);
    if (!formula)
        return null;
    
    const convertUnits = (formula, isMetric) => {
        return {
            ...formula,
            total_Two: isMetric ? ((formula.total_Two * 0.453592) / 3.78541).toFixed(2) : formula.total_Two,
            costDry: isMetric ? (formula.costDry * 0.453592).toFixed(2) : formula.costDry, 
            costGal: isMetric ? (formula.costGal * 3.78541).toFixed(2) : formula.costGal,
        };
    };
    
    const convertedFormula = convertUnits(formula, isMetric);

    const handleUnitToggle = (isMetric) => {
        console.log(`Switching to ${isMetric ? "Metric" : "Imperial"}`);
        setIsMetric(isMetric);
    };

    const unitToggleButtons = (
        <Grid container>
            <Grid item>
                <Button onClick={() => handleUnitToggle(false)} variant={!isMetric ? "contained" : "outlined"}>
                    Imperial
                </Button>
            </Grid>
            <Grid item>
                <Button onClick={() => handleUnitToggle(true)} variant={isMetric ? "contained" : "outlined"}>
                    Metric
                </Button>
            </Grid>
        </Grid>
    );

    const handleOnChange = (e) => {
        const newVal = Number(e.target.value);
        if (!isNaN(newVal) && newVal != formula.calculationType) {
            const upd = produce(formula, u => { u.calculationType = newVal });
            //TODO: Combine?
            dispatch(UpdateFormula(upd, formula));
            dispatch(FormulaCalcSummary(formula.id));
        }
    };
    const handleNameChange = (e) => {
        if (e.target.value != formula.name) {
            const upd = produce(formula, u => { u.name = e.target.value });
            dispatch(UpdateFormula(upd, formula));
        }
    };
    const handleCreatedOnChanged=(value)=>{
        if (value != formula.costUpdatedOn) {
            const upd = produce(formula, u => { u.costUpdatedOn = value });
            dispatch(UpdateFormula(upd, formula));
        }
    }
    const auditFormula = () => {
        setHistoryOpen(true);
    }
    const runFix = () => {
        if (formula) {
            dispatch(ReOrderFormulaSteps(formula.id));
            dispatch(FormulaCalcSummary(formula.id));
        }
    };
    const delFormula = () => {
        if (formula) {
            dispatch(ConfirmAction("Do you want to delete this formula?",DeleteFormula(formula),null));
        }
    }
    const handleClose = () => setHistoryOpen(false);
    let solidsPer = null;
    if (formula.calculationType == 0) {
        //Standard
        solidsPer = <TextField
            id="pearlOnly-read-only"
            type="number"
            label="Pearl Only"
            value={formula.pearlOnly}
            InputProps={{
                readOnly: true,
            }}
            InputLabelProps={{
                shrink: true,
            }}
        />;
    }
    if (formula.calculationType == 1) {
        //Westrock
        solidsPer = (
            
                <TextField
                id="percentSolids-read-only"
                    label="Batch Solids %"
                    value={formula.percentSolids}
                    InputProps={{
                        readOnly: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                />
            
        );
    }
    const summary = (
        <>
        <TextField
            id="batchweight-read-only"
            type="number"
            label="Batch Weight"
            value={formula.batchWeight}
            InputProps={{
                readOnly: true,
            }}
            InputLabelProps={{
                shrink: true,
            }}
        />
            {solidsPer}
        <TextField
            id="batchVolume-read-only"
            type="number"
            label="Batch Volume"
            value={formula.batchVolume}
            InputProps={{
                readOnly: true,
            }}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            id="boraxToCaustic-read-only"
            type="number"
            label="Borax to Caustic Ratio %"
            value={formula.boraxToCaustic}
            InputProps={{
                readOnly: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
        />
            <TextField
                id="total_Two-read-only"
                type="number"
                label={isMetric ? "Dry KG/ Liter pearl only" : "Dry lbs/ Gallon pearl only"}
                value={convertedFormula.total_Two}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                id="costdry-read-only"
                type="number"
                label={isMetric ? "Cost Per Dry KG" : "Cost Per Dry Lb"}
                value={convertedFormula.costDry}
                InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                id="costGal-read-only"
                type="number"
                label={isMetric ? "Cost Per Litre" : "Cost Per Gal"}
                value={convertedFormula.costGal}
                InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                InputLabelProps={{ shrink: true }}
            />
        <TextField
            id="totalCost-read-only"
            type="number"
            label="Total Cost"
            value={formula.totalCost}
            InputProps={{
                readOnly: true,
                startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                InputLabelProps={{
                    shrink: true,
                }}
        />
        </>
    );
     
    
    
    const buttons = <>
    {unitToggleButtons}
        <div>
            <Button onClick={()=>setTargetsOpen(true)}>Targets</Button>
        </div>
        <div>
            <Button onClick={runFix}>ReCalculate</Button>
        </div>
        <div>
            <Button onClick={delFormula}>Delete</Button>
        </div>
        <div>
            <Button onClick={auditFormula}>History</Button>
        </div>
        <div>
            <Button onClick={() => setPrint(true)}>Print</Button>
        </div>
        <div>
            <Button onClick={() => setSigOpen(true)}>Signature</Button>
        </div>
    </>;
    const footer = <Typography variant="h5">Signature:_____________________________</Typography>;
    const header = <Typography variant="h5">{formula.name}</Typography>;
    let printDialog = null;
    if (printOpen) {
        printDialog = <Dialog open={printOpen} onClose={() => setPrint(false)} fullScreen>
            <DialogContent>
                <FormulaPrintView formula={formula} onClose={() => setPrint(false)}  />
            </DialogContent>
        </Dialog>;
    }
    

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    return (
        <div>
        <Card>
            <CardContent>
                    <FormulaNameTextbox value={formula.name} handleNameChange={handleNameChange} />
                    <FormulaTypeSel value={formula.calculationType} onChange={handleOnChange}  />
                    <FormulaActiveCB formula={formula} />
                    {summary}
                    <CostChangedOnEdit value={formula.costUpdatedOn} onChange={handleCreatedOnChanged} />
                {buttons}
            </CardContent>
            </Card>
            <Dialog fullScreen open={historyOpen} onClose={handleClose} >
                <AppBar >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <FormulaChangeGrid formulaID={props.formulaID} />
            </Dialog>
            <FormulaSignatureDialog open={sigOpen} onClose={() => setSigOpen(false)} formula={ formula } /> 
            <FormulaTargetsDialog formula={ formula } open={ targetsOpen } onClose={()=>setTargetsOpen(false)} />
            { printDialog }
            </div>
    );
}
const FormulaNameTextbox = props => {
    const [value, setValue] = useState(props.value);
    const [initVal, setInitVal] = useState(props.value);
    //Devired State from Props hack
    if (props.value != initVal) {
        setValue(props.value);
        setInitVal(props.value);
    }
    return <TextField
        id="formula_name_txt"
        type="text"
        label="Name"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputLabelProps={{
            shrink: true,
        }}
        onBlur={props.handleNameChange}
    />
};
const FormulaActiveCB = props=>{
    const dispatch = useDispatch();
    const handleChange=(e)=>{
        const upd = produce(props.formula, u => { u.active = e.target.checked  });
        dispatch(UpdateFormula(upd, props.formula));
    }
    return  <FormControlLabel
    control={
        <Checkbox
            checked={props.formula?.active}
            onChange={handleChange}
            name="active"
            color="primary"
        />
    }
    label="Active"
/>
}
const CostChangedOnEdit = ({value,onChange})=>{
    console.debug("CostChangedOnEdit Render",value);
    let stripVal=value;
    if (stripVal && stripVal.indexOf('T') > 0) {
        stripVal = stripVal.substring(0, stripVal.indexOf('T'));
    }
    if (stripVal === '0001-01-01') {
        stripVal = '2022-01-01';
    }
    const handleChange=(e)=>{
        console.log("CostChangedOnEdit Value Changed New Val"+e.target.value );
        if (e.target.value && e.target.value !== value) {
            onChange(e.target.value);
        }
    };
    return <TextField
    id="costChanged_Input"
    label="Cost Last Changed On"
    value={stripVal}
    onBlur={handleChange}
        onChange={handleChange}
        
    type="date"
    InputLabelProps={{
        shrink: true,
    }}
    variant="outlined"
/>;
};