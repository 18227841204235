import Constants from '../../constants';
//Library Imports
import React, { useEffect, useState } from 'react';
import { useReportUser, useReportNameFromVisitID } from '../../misc/UseFuncs';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ReportViewer from '../Report/ReportViewer';
import { useReportName } from '../../misc/UseFuncs';
import { AttachmentsByFilterArray } from '../../selectors/AttachmentSelectors';
import { GetAttachmentsByArray } from '../../actions/AttachmentActions';
import { ScaleImages } from '../../misc/ImageFuncs';
import { ErrorNotification } from '../../actions/NotificationActions';
import { liftReducerWith } from '@redux-devtools/instrument';
const KeyItemsPrint = props => {


    let count=0;
    const startInd=props.filter.page*props.filter.rows;
    const endInd=startInd+props.filter.rows;
    console.log("KeyItems Start:"+startInd+" End:"+endInd)
    const keyItemsData = props.keyItems
        ?.filter(ki => {
            if (ki.deleted) { return false; }
            if (!props.filter)
                return true;
            let retVal = true;
            if (props.filter.entityTypes && props.filter.entityTypes.length > 0) {
                if (!props.filter.entityTypes.includes(ki.entityType))
                    return false;
            }
            if (props.filter.referecnes && props.filter.referecnes.length > 0) {
                if (!props.filter.referecnes.includes(ki.reference))
                    return false;
            }
            if (props.filter.visitStartDate && props.filter.visitStartDate.length > 0) {

                const dateFilter1 = props.filter.visitStartDate[0] || null;
                const dateFilter2 = props.filter.visitStartDate[1] || null;
                const valDate = ki.startDate.substring(0, 10);
                if (dateFilter1 && dateFilter2) {
                    if (valDate < dateFilter1 || valDate > dateFilter2) {
                        return false;
                    }
                } else if (dateFilter1) {
                    //Specific Day Filter
                    if (valDate !== dateFilter1) {
                        return false;
                    }
                }
            }
            if (props.filter.visitTypes && props.filter.visitTypes.length > 0) {
                if (!props.filter.visitTypes.includes(ki.type))
                    return false;
            }
            if (props.filter.notes) {
                if (!ki.notes.includes(props.filter.notes))
                    return false;
            }
            return true;
        })
        .sort((a, b) => {
            if (!props.sort)
                return 0;
            let valA = null;
            let valB = null;
            if (props.sort.direction === 'asc') {
                valA = a[props.sort.column].toUpperCase();
                valB = b[props.sort.column].toUpperCase();
            } else {
                valA = b[props.sort.column].toUpperCase();
                valB = a[props.sort.column].toUpperCase();
            }
            if (valA < valB) {
                return -1;
            }
            if (valA > valB) {
                return 1;
            }

            // names must be equal
            return 0;
        })
        .filter(ki=>{
            let retVal=true;
            if(count<startInd || count>=endInd)
                retVal= false;
            count++;
            return retVal;
        });

        console.log("Filtered and Paginated keyItems:", keyItemsData);
    
    const printData=useKeyItemsPrintData(keyItemsData,props.plant,props.visit);
    let rName = "KeyItem";
    if (props.printHistory) {
        rName = "KeyItemHistory"
    }
    if(printData.Loaded){
        return <ReportViewer type="KeyItem" name={rName} printData={printData}
            onClose={props.onClose} />;
    }
    return <div>Loading Data</div>;
}
export default KeyItemsPrint;
export const useKeyItemsPrintData=(keyItemsData,plant,visit)=>{
    const dispatch = useDispatch();

    const keyItems=keyItemsData.map((i,ind)=>{return {...i,lineNo:ind+1, isItemPriorty:i.isPriority}});
    // const keyItems = keyItemsData.map((i, ind) => { return { ...i, lineNo: ind + 1, isItemPriorty1: i.isPriority === 'Yes' ? ' (Priority)' : '', isItemPriorty: i.isPriority , isImageData: i.imageID !== '00000000-0000-0000-0000-000000000000' ? true : false } });

    const [scaled, setScaled] = useState(false);
    const [scaledAttachements, setScaledAttachments] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const UserData = useReportUser();
    const reportName = useReportName("KeyItems", plant?.name,visit);
    const online =useSelector(s=>s.offline.online,shallowEqual);
    const LastSync=useSelector(s=>s.LastSync,shallowEqual);
    const Token=useSelector(s=>s.Auth.token,shallowEqual);
    const imageIDs=keyItems.filter(ki=>ki.imageID!=Constants.EmptyGuid).map(ki=>ki.imageID);
    useEffect(() => {
        if ( online){
            GetAttachmentsByArray(keyItems,dispatch,LastSync,Token)
            .then(
                ()=>setTimeout(()=>setLoaded(true),200),
                ()=>{
                    dispatch(ErrorNotification("Error Loading Attachments for Report",{File:"KeyItemsPrint.js",Function:"useKeyItemsPrintData"}));
                    setTimeout(() => setLoaded(true), 200);
                }
            );
        } else {
            setTimeout(() => setLoaded(true), 200)
        }
    }, [JSON.stringify(imageIDs)]);
    const attachments = useSelector(state => AttachmentsByFilterArray(state, keyItems));
    let visitDate='';
    if(visit?.startDate){
        const d=new Date(visit.startDate);
        visitDate=+(d.getMonth()+1).toString().padStart(2,"0")+"/"+d.getDate().toString().padStart(2,"0")+"/"+d.getFullYear();
    }
    const retVal ={Loaded:false,Data:{},ReportName:reportName,EmailSubject:'Key Items '+plant?.name+' '+visitDate};
    if (loaded) {
        if (scaled) {
            const dJSON = { KeyItems: keyItems, User: UserData, Plant: plant, Visit: visit, Attachments: scaledAttachements };
            const Data = { KeyItemsData: JSON.stringify(dJSON) };
            retVal.Loaded=true;
            retVal.Data=Data;
            
        } else {
            Promise.all(ScaleImages(attachments, 200, 220))
                .then((values) => {

                    setScaledAttachments(values.map(v=>{
                        const kid=keyItems.find(d=>d.imageID==v.id);
                        return {...v, lineNo:kid.lineNo, entityType:kid.entityType, reference:kid.reference }
                    }));
                    setScaled(true);
                });
        }
    } 
    return retVal;
}