import React, { useEffect, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Grid, Box, Typography, Button, CircularProgress, MenuItem, Select, FormControl, InputLabel, TextField, FormControlLabel, Checkbox, Radio, RadioGroup, InputAdornment  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';

// Actions
import { AddMeasurement } from '../../actions/MeasurementActions';

// Component
const StarchChecksAdd = props => {
    const params = useParams();
    const visitID = params.VisitID;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isloading, setisloading] = useState(false);

    console.log('____11________________________________________________________________________');
    const [selectedStarch, setselectedStarch] = useState(null);
    const [Temperature, setTemperature] = useState(0);
    const [Viscosity, setViscosity] = useState(0);
    const [GelTemp, setGelTemp] = useState(0);
    const [Starch, setStarch] = useState(null);
    const [ReqObj, setReqObj] = useState({
        id: 0, name: "", value: 0, listData: []
    });
    const [selectedErr, setselectedErr] = useState(false);
    const [measurementErr, setMeasurementErr] = useState('');
    const [isCelsius, setIsCelsius] = useState(false);
    const UpdateId = params.StarchChID;
    const measeList = [{
        id: 0, name: "Mixer", value: 0, listData: []
    }, {
        id: 1, name: "Storage", value: 1, listData: []
    }, {
        id: 2, name: "Doser - SF #1", value: 2, listData: []
    },
    {
        id: 3, name: "Doser - SF #2", value: 3, listData: []
    }, {
        id: 4, name: "Doser - DB", value: 4, listData: []
    }];
    const initVals = {
        name: '',
        gelTempVal: 0,
        viscosityVal: 0,
        tempVal: 0
    }




    const [name, setname] = React.useState('');
    const [selectedChecObj, setselectedChecObj] = React.useState(null);


    useEffect(() => {
        console.log('name', initVals);
    }, [initVals]);


    const handleChange = (event) => {
        console.log('check 111', event.target);
        setselectedChecObj(event.target.value);
        setselectedErr(false)
    };



    const handleClose = () => {
        navigate('/VisitOverview/' + visitID );
    }
    const saveCheck = () => {
        if (selectedStarch === null) {
            setselectedErr(true)
        }
    }

    const validate = (values) => {
        const errors = {};
        if (isNaN(values.gelTempVal)) { errors.gelTempVal = "Please enter a valid number"; }
        if (isNaN(values.viscosityVal)) { errors.viscosityVal = "Please enter a valid number"; }
        if (isNaN(values.tempVal)) { errors.tempVal = "Please enter a valid number"; }
        return errors;
    };
    const handelgelTemp = (e) => {
        setGelTemp(e.target.value)
        setMeasurementErr('')
    }
    const handelViscocity = (e) => {
        setViscosity(e.target.value)
        setMeasurementErr('')
    }
    const handelTemp = (e) => {
        setTemperature(e.target.value)
        setMeasurementErr('')
    }
    const handleUnitChange = () => {
        setIsCelsius(!isCelsius);
    };

    const handleSubmit = (e) => {
        if (Number(GelTemp) > 0 || Number(Viscosity) > 0 || Number(Temperature) > 0) {
            setisloading(true);

            const tempInFahrenheit = isCelsius ? Temperature * 9 / 5 + 32 : Temperature;
            const gelTempInFahrenheit = isCelsius ? GelTemp * 9 / 5 + 32 : GelTemp;
            // const viscosityInFahrenheit = isCelsius ? Viscosity * 9 / 5 + 32 : Viscosity;

            if (Number(gelTempInFahrenheit) > 0) {
                dispatch(AddMeasurement({
                    type: 0, location: selectedChecObj,
                    visitID: visitID, value: gelTempInFahrenheit, measurementDateTime: new Date().toJSON()
                }));
            }
            if (Number(Viscosity) > 0 > 0) {
                dispatch(AddMeasurement({
                    type: 1, location: selectedChecObj,
                    visitID: visitID, value: Number(Viscosity), measurementDateTime: new Date().toJSON()
                }));
            }
            if (Number(tempInFahrenheit) > 0) {
                dispatch(AddMeasurement({
                    type: 2, location: selectedChecObj,
                    visitID: visitID, value: tempInFahrenheit, measurementDateTime: new Date().toJSON()
                }));
            }
            setTimeout(() => {
                navigate('/VisitOverview/' + visitID + '?' + 'StartchChecks-' + selectedChecObj);
            }, 1000);

        } else {
            setMeasurementErr('Please add at least one Measurement')
        }
    };

    const unitLabel = isCelsius ? '°C' : '°F';
    return (
        <div>

            <Grid container spacing={3} sx={{ marginBottom: 12 }}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={4} align="left">
                            <Typography component={'h6'} variant='h6'> Starch Checks </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <div>
                        <div style={{ marginLeft: '12px' }}>
                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 300, maxWidth: 300 }}>
                                <InputLabel id="starch-checks-label">Select Starch Checks</InputLabel>
                                <Select
                                    labelId="starch-checks-label"
                                    id="starch-checks"
                                    value={selectedChecObj}
                                    label="Select Starch Checks"
                                    onChange={handleChange}
                                >
                                    {measeList.map((option) => (
                                        <MenuItem key={option.id} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                    {measeList.length === 0 && (
                                        <MenuItem key="none" value="" disabled>
                                            Record not found
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        {
                            selectedErr ? <Typography style={{ marginLeft: '20px', fontSize: '0.75rem' }} color="error">Please select starch checks</Typography> : <></>
                        }
                    </div>
                </Grid>

                <Grid item sx={{ marginLeft: 2 }} lg={6}>
                    {selectedChecObj !== null ? <>
                        <Box>
                            <Formik initialValues={initVals} onSubmit={handleSubmit} validate={validate}  >
                                <Form>
                                    <Grid sx={{ border: 1, borderColor: 'lightgray', padding: 2 }} container align="center" justify="center" alignItems="center">
                            
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Typography align="left" component={'h6'} variant='h6'>Add Measurement</Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <RadioGroup 
                                                row 
                                                value={isCelsius ? 'Celsius' : 'Fahrenheit'} 
                                                onChange={handleUnitChange} 
                                                sx={{ display: 'flex' }}
                                            >
                                                <FormControlLabel value="Fahrenheit" control={<Radio />} label="Fahrenheit" />
                                                <FormControlLabel value="Celsius" control={<Radio />} label="Celsius" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                        <Grid item xs={6}><Typography>Gel Temp</Typography></Grid>
                                        <Grid item xs={6}><Field component={TextField} onChange={handelgelTemp} name="gelTempVal" InputProps={{ endAdornment: <InputAdornment position="end">{unitLabel}</InputAdornment> }}/></Grid>
                                        <Grid item xs={6}><Typography>Viscosity</Typography></Grid>
                                        <Grid item xs={6}><Field component={TextField} onChange={handelViscocity} name="viscosityVal" type="number" InputProps={{ endAdornment: <InputAdornment position="end">{unitLabel}</InputAdornment> }}/></Grid>
                                        <Grid item xs={6}><Typography>Temperature</Typography></Grid>
                                        <Grid item xs={6}><Field component={TextField} onChange={handelTemp} name="tempVal" InputProps={{ endAdornment: <InputAdornment position="end">{unitLabel}</InputAdornment> }}/></Grid>
                                        {
                                            measurementErr !== '' ? 
                                            <Grid item xs={12} >
                                        <Typography align="left" style={{ marginLeft: '20px', fontSize: '0.75rem' }} color="error">{measurementErr}</Typography>
                                        </Grid> : <></>
                                        }
                                        
                                    </Grid>

                                    <Box>
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px', paddingLeft: 2 }}>
                                            <Button disabled={isloading} variant="contained" color="secondary" startIcon={<CloseIcon />} onClick={handleClose}>
                                                Close
                                            </Button>
                                            {
                                                isloading && UpdateId !== 'edit' ? <div style={{ marginLeft: '12px' }}><CircularProgress /></div> :

                                                    UpdateId !== 'edit' ?

                                                        <Button sx={{ marginLeft: 2 }} variant="contained" type="submit">
                                                            Save
                                                        </Button> : <></>
                                            }
                                        </div>
                                    </Box>
                                </Form>
                            </Formik>
                        </Box>
                    </> : <>

                        <Box>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px', paddingLeft: 2 }}>
                                <Button disabled={isloading} variant="contained" color="secondary" startIcon={<CloseIcon />} onClick={handleClose}>
                                    Close
                                </Button>
                                {
                                    isloading && UpdateId !== 'edit' ? <div style={{ marginLeft: '12px' }}><CircularProgress /></div> :

                                        UpdateId !== 'edit' ?

                                            <Button sx={{ marginLeft: 2 }} onClick={saveCheck} variant="contained">
                                                Save
                                            </Button> : <></>
                                }
                            </div>
                        </Box>

                    </>
                    }
                </Grid>
            </Grid>
        </div>
    );
};

export default StarchChecksAdd;
