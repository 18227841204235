//Modules
import React, { useState,useRef,useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {useNavigate, Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { OpenDrawer, CloseDrawer } from '../../actions/MenuActions'
import { ReSync, WipeAndRestore } from '../../actions/DataActions';
import { Logout } from '../../actions/AuthActions';
import logger from '../../logger';
import localforage from 'localforage';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
const MenuList = props => {
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const ToggleFullScreen = () => {
        if (document.fullscreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        } else {
            var elem = document.documentElement;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        }
        dispatch(CloseDrawer());
    };
  
    return <List>
        <ListItem button key={'FullScreen_Button'} onClick={ToggleFullScreen} >
            <ListItemText primary={document.fullscreen?'Minimize':'FullScreen'} />
        </ListItem>

        <ListItemButton component="a" href="/ListReports">
            <ListItemText primary="Reports" />
        </ListItemButton>
        <ListItem button key={'Logout_Button'} onClick={() => {
            localforage.clear();
            dispatch(Logout());
            dispatch(CloseDrawer());
        }}>
            
            <ListItemText primary={'Logout'} />
        </ListItem>

        <ListItem button key={'Resync_Button'} onClick={() => {
            navigate('/');
            dispatch(ReSync());
            dispatch(CloseDrawer());
        }}>
            <ListItemIcon><SyncOutlinedIcon /></ListItemIcon>
            <ListItemText primary={'ReSync'} />
        </ListItem>
        <ListItem button key={'Wipe_Button'} onClick={() => {
            dispatch(ConfirmAction("This will delete all local data and reload. Please download log before running", WipeAndRestore(), null,
                (confirmed) => {
                    if (confirmed) {
                        navigate('/');
                        dispatch(CloseDrawer());
                    }
                }));            
        }}>
            
            <ListItemText primary={'Wipe and Restore'} />
        </ListItem>
        <ListItem key={'Version_menu'}>
            <ListItemText>
                Version 1.0.17
            </ListItemText>
        </ListItem>
        <ListItem>
            <DownloadButton />   
        </ListItem>
        <ListItem>
            <Link to={"/AddReport"}>Add Report</Link>
        </ListItem>
        </List>
}
export default MenuList;
const LogDownload = props => {
    const state = useSelector(s => s);
    const aRef = useRef(null);
    const logData = {
        state: state,
        log: logger.ExportLog()
    }
    useEffect(() => {
        aRef.current.click();
        props.Clear();
    }, []);
    return <a ref={aRef} href={'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(logData))} download="log.json" >TodoDownload</a>;
}
const DownloadButton = props => {
    const [clicked, SetClicked] = useState(false);
    const clear = () => SetClicked(false);
    let download = null;
    if (clicked) {
        download = <LogDownload Clear ={ clear } />;
    }
    
    return <>
        <Button onClick={e => SetClicked(true)}>Download Log</Button>
        {download}
    </>;

}
