import React, { useState } from 'react';
import { FormulaSelection } from './FormulaSelectionView';
import FormulaEdit from './FormulaEditView';
import { Grid, ButtonGroup, Button } from '@mui/material'; // Import necessary components

export const FormulasEditView = props => {
    const [selectedFormula, setSelFormula] = useState('0');
    const [measurementSystem, setMeasurementSystem] = useState('imperial'); // Default to imperial

    const isMetric = measurementSystem === 'metric'; // Determine if metric is selected

    let fe = null;
    if (selectedFormula !== '0') {
        fe = (
            <FormulaEdit 
                formulaID={selectedFormula} 
                visitID={props.visitID} 
                measurementSystem={measurementSystem} // Passing measurementSystem
                isMetric={isMetric} // Passing isMetric
            />
        );
    }

    return (
        <div>
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <FormulaSelection 
                        plantID={props.plantID} 
                        addLine 
                        selected={selectedFormula} 
                        handleChange={newVal => setSelFormula(newVal)} 
                    />
                </Grid>
                {/* Show button group only when a formula is selected */}
                {selectedFormula !== '0' && (
                    <Grid item>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group">
                            <Button 
                                onClick={() => setMeasurementSystem('imperial')} 
                                variant={measurementSystem === 'imperial' ? "contained" : "outlined"}
                            >
                                Imperial
                            </Button>
                            <Button 
                                onClick={() => setMeasurementSystem('metric')} 
                                variant={measurementSystem === 'metric' ? "contained" : "outlined"}
                            >
                                Metric
                            </Button>
                        </ButtonGroup>
                    </Grid>
                )}
            </Grid>
            {fe}
        </div>
    );
};
