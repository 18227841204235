import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
export const FormulaTypeSel = props => {
    const options = (<>
        <option key={0} value={0}> Starch Only</option>
        <option key={1} value={1}> All Solids</option>
    );
    </>);
    return (
        <FormControl >
            <InputLabel htmlFor="Formula_Type_Selection">Type</InputLabel>
            <NativeSelect
                id="Formula_Type_Selection"
                name={'calculationType'}
                value={props.value}
                onChange={props.onChange}
            >
                {options}
            </NativeSelect>
        </FormControl>
    );
};